/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');


// fonts
$base-font-size: 15;
$base-font: 'Manrope', sans-serif;;
$heading-font: 'Manrope', sans-serif;



// color
$dark-gray: #000B47;
$body-color: #687693;
$white: #fff;
$light: #F8F8F8;//
$black: #000;//
$small-black: #625F71;//
$cyan: #525252;//

$theme-primary-color: #FF5E14;//
$theme-primary-color-s2: #233FD6;//
$body-bg-color: #fff;
$section-bg-color: #fafbfe;
$text-color: #1A1729;//
$text-light-color: #545454;//
$heading-color: $dark-gray;
$border-color: #ebebeb;
$border-color-s2: #d8e0f1;