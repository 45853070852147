/*3.2 wpo-feature-section */
.wpo-feature-section {
    position: relative;
    z-index: 1;
    margin-top: -165px;

    @media (max-width:991px) {
        margin-top: 90px;
    }

    @media (max-width:767px) {
        margin-top: 80px;
    }

    .wraper {
        max-width: 1426px;
        margin: 0 auto;
        background: #FFF;
        box-shadow: 0px 2px 8px 0px rgba(0, 12, 55, 0.10);
        padding: 40px 50px 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width:1399px) {
            max-width: 1170px;
        }

        .item {
            position: relative;
            width: 25%;
            margin-bottom: 30px;
            padding-left: 90px;

            @media (max-width:1399px) {
                padding-left: 50px;
            }

            @media (max-width:1199px) {
                padding-left: 30px;
            }

            @media (max-width:991px) {
                width: 50%;
                padding-left: 0;
                text-align: center;
            }

            @media (max-width:575px) {
                width: 100%;
            }

            &::before {
                width: 1px;
                height: 130px;
                background: #E8D6CF;
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);

                @media (max-width:575px) {
                    display: none;
                }
            }

            &:last-child {
                &::before {
                    display: none;
                }
            }

            &:nth-child(2) {
                &::before {
                    @media (max-width:991px) {
                        display: none;
                    }
                }
            }

            &:last-child {
                padding-left: 100px;

                @media (max-width:1399px) {
                    padding-left: 90px;
                }

                @media (max-width:1199px) {
                    padding-left: 50px;
                }

                @media (max-width:991px) {
                    padding-left: 0px;
                }
            }

            .icon {
                margin-bottom: 20px;
            }

            .content {
                h2 {
                    color: $heading-color;
                    font-size: 40px;
                    font-weight: 600;

                    @media (max-width:575px) {
                        font-size: 35px;
                    }

                    span {
                        color: $heading-color;
                        font-size: 50px;
                        font-weight: 700;

                        @media (max-width:575px) {
                            font-size: 35px;
                        }
                    }
                }

                h3 {
                    color: $small-black;
                    font-size: 25px;
                    font-weight: 600;

                    @media (max-width:575px) {
                        font-size: 22px;
                    }
                }

                span {
                    color: $small-black;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }
    }
}

/*3.3 wpo-about-section */
.wpo-about-section {
    @media (max-width:991px) {
        padding-bottom: 0;
    }

    .about-left-image {
        position: relative;
        z-index: 1;
        max-width: 730px;
        max-height: 810px;
        margin-left: -150px;

        @media (max-width:1399px) {
            margin-left: 0px;
        }

        @media (max-width:991px) {
            margin-bottom: 100px;
        }

        @media (max-width:575px) {
            margin-bottom: 80px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &::before {
            position: absolute;
            left: -10px;
            top: -10px;
            max-width: 496px;
            width: 100%;
            max-height: 535px;
            height: 100%;
            content: '';
            background: $theme-primary-color;
            z-index: -1;
        }

        .ab-2 {
            position: absolute;
            right: -60px;
            bottom: -100px;
            max-width: 400px;
            max-height: 400px;
            border-radius: 100%;
            border: 10px solid $white;

            @media (max-width:1399px) {
                max-width: 250px;
                max-height: 250px;
                right: 0px;
            }

            @media (max-width:575px) {
                max-width: 150px;
                max-height: 150px;
                bottom: -50px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 100%;
            }
        }
    }

    .content {
        h2 {
            color: $theme-primary-color-s2;
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 5px;

            @media (max-width:575px) {
                font-size: 20px;
            }
        }

        h3 {
            color: $heading-color;
            font-size: 50px;
            font-weight: 700;
            line-height: 75px;
            margin-bottom: 20px;

            @media (max-width:1399px) {
                font-size: 45px;
            }

            @media (max-width:1199px) {
                font-size: 40px;
                line-height: 50px;
            }

            @media (max-width:575px) {
                font-size: 25px;
                line-height: 45px;
            }
        }

        p {
            color: $cyan;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 25px;
            max-width: 623px;

            @media (max-width:575px) {
                font-size: 15px;
            }
        }

        .ceo-content {
            padding-top: 20px;
            max-width: 620px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width:575px) {
                display: block;
            }

            .text {

                @media (max-width:575px) {
                    margin-bottom: 20px;
                }

                h2 {
                    color: $heading-color;
                    font-size: 30px;
                    font-weight: 600;

                    @media (max-width:1199px) {
                        font-size: 20px;
                    }
                }

                span {
                    color: $text-light-color;
                    font-size: 18px;
                    font-weight: 400;

                    @media (max-width:1199px) {
                        font-size: 16px;
                    }
                }
            }

            .image {}
        }
    }
}

/*3.4 wpo-service-section */
.wpo-service-section {
    padding-top: 50px;

    &.s2 {
        padding-top: 120px;

        @media (max-width:991px) {
            padding-top: 90px;
        }

        @media (max-width:767px) {
            padding-top: 80px;
        }
    }

    .wpo-section-title {
        @media (max-width:767px) {
            text-align: center;
        }
    }

    .service-wrap {
        padding-left: 160px;

        @media (max-width:1520px) {
            padding-left: 120px;
        }

        @media (max-width:1299px) {
            padding-left: 30px;
        }

        @media (max-width:1199px) {
            padding-left: 20px;
        }

        @media (max-width:767px) {
            padding-left: 0px;
            padding: 0;
        }
    }
}

.service-slider {

    .slick-slide {
        padding: 0 20px;

        @media (max-width:767px) {
            padding: 0 10px;
            text-align: center;
        }
    }

    .item {
        @media (max-width:767px) {
            max-width: 450px;
            margin: 0 auto;
        }

        .image {
            position: relative;
            z-index: 1;
            overflow: hidden;

            img {
                width: 100%;
                transition: all .8s ease-in-out;
            }

            span {
                position: absolute;
                right: 15px;
                top: 0px;
                font-size: 60px;
                font-weight: 800;
                color: transparent;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: $white;
                z-index: 1;
            }

            .line-one {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                &::before {
                    position: absolute;
                    left: 10px;
                    bottom: -10px;
                    width: 1px;
                    height: 0;
                    content: '';
                    background: $white;
                    transition: all .2s ease-in-out;
                }

                &::after {
                    position: absolute;
                    right: 10px;
                    top: -10px;
                    width: 1px;
                    height: 0;
                    content: '';
                    background: $white;
                    transition: all .5s ease-in-out;
                }

            }

            .line-two {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                &::before {
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    width: 0;
                    height: 1px;
                    content: '';
                    background: $white;
                    transition: all 1s ease-in-out;
                }

                &::after {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                    width: 0;
                    height: 1px;
                    content: '';
                    background: $white;
                    transition: all .4s ease-in-out;
                }

            }

        }

        .content {
            background: $light;
            padding: 40px 30px;

            @media (max-width:1750px) {
                padding: 40px 20px;
            }

            .icon {
                width: 70px;
                height: 70px;
                background: $theme-primary-color;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                margin-top: -90px;
                z-index: 1;
                transition: all .3s ease-in-out;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            h2 {
                margin-top: 20px;

                a {
                    color: #000;
                    font-size: 25px;
                    font-weight: 700;
                    transition: all .3s ease-in-out;

                    @media (max-width:1599px) {
                        font-size: 22px;
                    }


                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }

            p {
                color: $text-light-color;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
                margin-bottom: 0;
            }
        }

        &:hover {
            .image {

                img {
                    transform: scale(1.2);
                }

                .line-one {

                    &::before {
                        height: 100%;
                    }

                    &::after {
                        height: 100%;
                    }

                }

                .line-two {

                    &::before {
                        width: 100%;
                    }

                    &::after {
                        width: 100%;
                    }

                }

            }

            .content {

                .icon {
                    transform: rotateY(180deg);
                }
            }
        }
    }

    .owl-nav {
        display: none;
    }
}

/*3.5 wpo-history-section */
.wpo-history-section {
    padding: 100px 0;
    position: relative;
    z-index: 1;
    background: url(../../assets/background/bg-aluminium.jpg) center / cover no-repeat;

    @media (max-width:1399px) {
        margin-bottom: 70px;
    }

    @media (max-width:991px) {
        padding: 50px 0;
        margin-bottom: 90px;
    }

    @media (max-width:767px) {
        padding: 40px 0;
        margin-bottom: 80px;
    }


    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: rgba(0, 0, 0, 0.7);
        z-index: -1;
    }

    .content {
        max-width: 561px;

        @media (max-width:991px) {
            text-align: center;
            margin: 0 auto;
        }

        span {
            color: #7C90F9;
            font-size: 22px;
            font-weight: 600;
        }

        h2 {
            color: #FFF;
            font-size: 50px;
            font-weight: 700;
            line-height: 70px;
            margin-bottom: 20px;

            @media (max-width:1199px) {
                font-size: 40px;
                line-height: 60px;
            }

            @media (max-width:575px) {
                font-size: 35px;
                line-height: 50px;
            }
        }

        p {
            color: #FFF;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 35px;
        }

        .btn_history {


            a {
                color: $theme-primary-color;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                width: 230px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #FF5E14;
                transition: all .3s ease-in-out;

                @media (max-width:991px) {
                    margin: 0 auto;
                    text-align: center;
                }

                @media (max-width:575px) {
                    font-size: 18px;
                    width: 200px;
                    height: 50px;
                }

                &:hover {
                    background: $theme-primary-color;
                    color: $white;
                }
            }

        }
    }
}

.video-content {
    width:409px;
    height:  333px;
    padding: 10px;
    padding-bottom: 0;
    position: absolute;
    z-index: 1;
    right: 80px;
    top: 80px;

    @media (max-width:1799px) {
        right: 0px;
    }

    @media (max-width:1650px) {
        max-width: 850px;
    }

    @media (max-width:1550px) {
        max-width: 750px;
    }

    @media (max-width:1399px) {
        max-width: 500px;
        max-height: 500px;
        padding-bottom: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    @media (max-width:991px) {
        position: relative;
        transform: unset;
        margin: 0 auto;
        margin-top: 50px;
    }

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width:409px;
        height:  333px;
        content: '';
        background: $theme-primary-color;
        z-index: -1;

        @media (max-width:1399px) {
            height: 100%;
        }
    }

    img {
        width: 100%;
        object-fit: cover;

        @media (max-width:1399px) {
            height: 100%;
        }
    }

    .video-btn {
        width: 120px;
        height: 120px;
        background: $white;
        border: 3px solid $theme-primary-color;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 50%;
        top: 65%;
        transform: translate(-50%, -50%);

        @media (max-width:1399px) {
            top: 50%;
            width: 100px;
            height: 100px;
        }

        @media (max-width:575px) {
            width: 80px;
            height: 80px;
        }



        span {
            width: 40px;
            height: 40px;
            background: $theme-primary-color;
            clip-path: polygon(100% 50%, 0 0, 0 100%);

            @media (max-width:1399px) {
                width: 30px;
                height: 30px;
            }

            @media (max-width:575px) {
                width: 20px;
                height: 20px;
            }
        }
    }
}

/*3.6 wpo-team-section */
.wpo-team-section,
.wpo-team-section-s2 {
    padding-top: 0;
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 50px;
    }

    .wpo-section-title {
        @media (max-width:767px) {
            text-align: center;
        }
    }
}

.wpo-team-section-s2 {
    padding-top: 120px;

    @media (max-width:991px) {
        padding-top: 90px;
    }

    @media (max-width:767px) {
        padding-top: 80px;
    }
}

.tema-card {
    margin-bottom: 30px;
    position: relative;

    .image {
        transition: all 1s ease-in-out;
        overflow: hidden;

        img {
            width: 100%;
            transition: all 1s ease-in-out;
        }
    }

    .content {
        max-height: 158px;
        background: linear-gradient(180deg, rgba(30, 46, 62, 0.00) 0%, rgba(30, 46, 62, 0.29) 28.65%, rgba(24, 40, 55, 0.78) 63.02%, #101A25 97.92%);
        padding-top: 65px;
        padding-bottom: 20px;
        text-align: center;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;

        h3 {

            a {
                color: $white;
                font-size: 30px;
                font-weight: 600;
                transition: all .3s ease-in-out;

                @media (max-width:1199px) {
                    font-size: 25px;
                }

                &:hover {
                    color: $theme-primary-color;
                }
            }
        }

        span {
            color: $theme-primary-color;
            font-size: 18px;
            font-weight: 500;

            @media (max-width:1199px) {
                font-size: 15px;
            }
        }

    }

    &:hover {
        .image {

            img {
                transform: scale(1.1);
            }
        }
    }
}

/*3.7 wpo-project-section */
.wpo-project-section {
    .container-fluid {
        padding: 0;
    }

    @media (max-width:600px) {
        .slick-initialized .slick-slide {
            margin: 0 auto;
            text-align: center;
        }
    }
}

.project-card {
    position: relative;
    z-index: 1;
    max-width: 480px;
    max-height: 680px;
    overflow: hidden;

    @media (max-width:575px) {
        max-width: 100%;
        margin: 0 auto;
    }

    .image {
        transition: all .3s ease-in-out;

        img {
            width: 100%;
            transition: all .3s ease-in-out;
        }
    }

    .content {
        visibility: hidden;
        opacity: 0;
        text-align: left;
        padding: 40px;
        position: absolute;
        left: 50%;
        bottom: -50px;
        width: 100%;
        transform: translateX(-50%);
        background: linear-gradient(180deg, rgba(30, 46, 62, 0.00) 0%, rgba(30, 46, 62, 0.29) 28.65%, rgba(24, 40, 55, 0.78) 63.02%, #101A25 97.92%);
        transition: all .3s ease-in-out;

        @media (max-width:1399px) {
            padding: 25px;
        }

        h3 {
            a {
                color: $white;
                font-size: 30px;
                font-weight: 700;

                @media (max-width:1399px) {
                    font-size: 25px;
                }
            }
        }

        p {
            color: $white;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 0;
        }
    }

    &:hover {

        .image {

            img {
                transform: scale(1.1);
            }
        }

        .content {
            visibility: visible;
            opacity: 1;
            bottom: 0;
        }

    }
}

/*3.8 wpo-testimonial-section */
.wpo-testimonial-section {
    .wpo-section-title {
        @media (max-width:767px) {
            text-align: center;
        }
    }

    .slick-list {
        margin: 0 -15px;
    }

    .slick-slide {
        padding: 0 15px;
    }

    .slick-dots li button {
        border: 0;
        width: 15px;
        height: 15px;
        margin: 5px;
        border-radius: 50%;
        background: $text-light-color;
        display: block;
        font-size: 0;

        &::before {
            font-size: 0;
        }
    }

    .slick-dots {
        bottom: -45px;
    }

    .slick-dots .slick-active button {
        background: $theme-primary-color;
    }

}

.testimonial-card {

    .content {
        padding: 40px;
        background: #fff;
        box-shadow: 4px 10px 25px #eaeced;
        position: relative;
        z-index: 1;

        @media (max-width:499px) {
            padding: 20px;
        }

        &::before {
            position: absolute;
            left: 55px;
            bottom: -20px;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid #fff;
            content: "";
        }

        &::after {
            position: absolute;
            left: 50%;
            top: 50%;
            content: "";
            background: url(../../images/testimonial/quotes.png) center no-repeat;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        p {
            font-style: italic;
            font-weight: 400;
            font-size: 18px;
            line-height: 160%;
            margin-bottom: 0;
        }
    }

    .client {
        display: flex;
        align-items: center;
        padding-left: 45px;
        margin-top: 40px;

        @media (max-width:499px) {
            padding-left: 20px;
        }

        .client-img {
            border-radius: 50%;
            background: #F0F0F0;
            padding: 5px;

            img {
                border-radius: 50%;
            }
        }

        .client-text {
            margin-left: 20px;
            margin-top: 15px;
            position: relative;


            h4 {
                font-weight: 600;
                font-size: 24px;
                line-height: 15px;
                display: flex;
                align-items: center;

                @media (max-width:499px) {
                    font-size: 20px;
                }
            }

            span {
                font-weight: 400;
                font-size: 15px;
                line-height: 160%;
            }
        }
    }


}


/*3.9 wpo-blog-section */
.wpo-blog-section {
    background: $light;
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 50px;
    }

    .wpo-section-title {
        @media (max-width:767px) {
            text-align: center;
        }
    }

}

.blog-card {
    margin-bottom: 30px;

    .image {
        transition: all .3s ease-in-out;
        overflow: hidden;

        img {
            width: 100%;
            transition: all .5s ease-in-out;
        }
    }

    &:hover {
        .image {

            img {
                transform: scale(1.2);
            }
        }
    }

    .content {
        padding: 30px 25px;
        background: $white;
        padding-top: 0;

        @media (max-width:1199px) {
            padding: 25px 20px;
            padding-top: 0;
        }

        @media (max-width:575px) {
            padding: 20px 15px;
            padding-top: 0;
        }

        ul {
            background: $theme-primary-color;
            display: inline-block;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            top: -20px;

            li {
                list-style: none;
                display: inline-block;
                font-weight: 400;
                font-size: 15px;
                line-height: 160%;
                padding: 7px 15px;
                color: $white;
                position: relative;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 5px;
                    height: 5px;
                    content: '';
                    background: $white;
                    transform: translate(-50%, -50%);
                }

                &:first-child {


                    &::before {
                        display: none;
                    }
                }
            }
        }

        h2 {
            a {
                color: $heading-color;
                font-size: 30px;
                font-weight: 700;
                line-height: 50px;
                transition: all .3s ease-in-out;

                @media (max-width:1399px) {
                    font-size: 25px;
                    line-height: 40px;
                }

                @media (max-width:1199px) {
                    font-size: 20px;
                    line-height: 30px;
                }

                &:hover {
                    color: $theme-primary-color;
                }
            }
        }

        p {
            color: $text-color;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
        }

        a {
            color: $heading-color;
            font-size: 18px;
            font-weight: 700;
            line-height: 30px;
            transition: all .3s ease-in-out;

            @media (max-width:1399px) {
                font-size: 15px;
                line-height: 30px;
            }

            &:hover {
                color: $theme-primary-color;
            }

            i {
                padding-left: 5px;
            }
        }
    }

}