/*=====================================================
8. wpo-project-single
======================================================*/
.wpo-project-single-area{
    padding-bottom: 80px;
    @media(max-width:991px){
        padding-bottom: 50px;
    }
    @media(max-width:767px){
        padding-bottom: 40px;
    }
    .wpo-project-single-wrap{
        .wpo-project-single-title{
            h3{
                font-weight: 700;
                font-size: 35px;
                line-height: 130.5%;
                margin-bottom: 20px;

                @media(max-width:767px){
                    font-size: 25px;
                }
                @media(max-width:575px){
                    font-size: 22px;
                }
            }
        }
        .wpo-project-single-item{
            margin-bottom: 40px;
            .wpo-project-single-main-img{
                margin-bottom: 30px;

                .owl-controls {
                    width: 100%;
                    margin: 0;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    @include translatingY();
                }
        
                .owl-nav [class*=owl-]:hover {
                    background: $theme-primary-color;
                    color: $white;
                }
        
                .owl-nav [class*=owl-] {
                    background: $white;
                    width: 50px;
                    height: 50px;
                    line-height: 58px;
                    padding: 0;
                    margin: 0;
                    border-radius: 50%;
                    color: $theme-primary-color;
                    @include transition-time(0.3s);
                    border: 0;
        
                    .fi{
                        &::before{
                            font-size: 20px;
                        }
                    }
                }
        
                .owl-nav .owl-prev,
                .owl-nav .owl-next {
                    position: absolute;
                    top: 50%;
                    @include translatingY();
                }
        
                .owl-nav .owl-prev {
                    left: 15px;
                }
        
                .owl-nav .owl-next {
                    right: 15px;
                }
            }

            .wpo-project-single-content-des-right{
                max-width: 380px;
                flex-basis: 35%;
                margin-left: auto;
                margin-bottom: 50px;
                @media(max-width:1200px){
                    flex-basis: 100%;
                    margin-bottom: 40px;
                 }
                @media(max-width:991px){
                    margin-left: 0;
                    max-width: 100%;
                    margin-top: 20px;
                 }

                ul{
                    list-style: none;
                    padding:50px 30px ;
                    box-shadow: 0px 2px 10px rgba(14, 29, 44, 0.15);

                    @media(max-width:575px){
                        padding: 50px 20px;
                    }

                    li{
                          display: flex;
                          padding: 10px 0;
                          font-size: 16px;
                          color: $dark-gray;
                          font-weight: 600;
                          position: relative;
                          justify-content: space-between;
                          font-weight: 600;

                          span{
                              flex-basis: 60%;
                              color: $body-color;
                              font-weight: 400;
                          }
        
                        &:last-child {
                            padding-bottom: 0;
                            border-bottom: 0;
                        }
                        &:first-child {
                             padding-top: 0;
                        }
                    }
                }
            }

        }
        .list-widget{
            ul{
                list-style: none;

                li{
                    padding: 7px 0;
                    padding-left: 35px;
                    position: relative;

                    &:before{
                       position: absolute;
                       left: 0;
                       top: 50%;
                       transform: translateY(-50%);
                       width: 25px;
                       height: 25px;
                       line-height: 25px;
                       border-radius: 50%;
                       background: transparentize($theme-primary-color, .9);
                       content: "\e64c";
                       font-family: "themify";
                       text-align: center;
                       color: $theme-primary-color;
                       font-size: 14px;
                    }
                }
            }
        }

        .wpo-p-details-img{
            margin-bottom: 10px;
        }

        .wpo-project-single-item-quote{
           padding: 45px;
           background: transparentize($theme-primary-color, .9);
           text-align: center;
           border-radius: 15px;

           @media(max-width:991px){
             padding: 20px;
             margin-top: 30px;
           }

           p{
               font-size: 22px;
               margin-bottom: 30px;
               color: $dark-gray;
               line-height: 44px;

               @media(max-width:991px){
                font-size: 16px;
                margin-bottom: 20px;
              }
           }

           span{
               font-size: 22px;
                color: black;
               @media(max-width:991px){
                font-size: 16px;
              }

               span{
                   color: $theme-primary-color;
               }
           }
        }

        .list-widget-s{
            @media(max-width:991px){
                margin-top: 30px;
              }
        }

        .wpo-project-single-gallery{
            margin-bottom: 30px;
        }

        .wpo-project-contact-area{
            padding: 30px;
            border-radius: 20px;
            box-shadow: 0px 2px 8px 2px rgb(22 14 71 / 10%);
            background: #fff;

            .wpo-contact-title{
                margin-bottom: 30px;
            }

            .wpo-contact-form-area{
                .col{
                   margin-bottom: 30px;
                }
                .form-control{
                    height: 50px;
                    border:0 ;
                    border-bottom: 1px solid $border-color;
                    border-radius: 0;
                    background: none;

                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }
                textarea.form-control{
                    height: 120px;
                }
                select.form-control{
                    background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
                }

                .submit-area{
                    button{
                    }
                }
            }
        }
        .grid {
            position: relative;
            overflow: hidden;
            z-index: 1;
            margin-bottom: 30px;

            @media (max-width:px) {
                top: 70%;
            }

            .images {
                position: relative;
                clip-path: polygon(100% 0, 100% 88%, 88% 100%, 0 100%, 0 0);

                &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, rgba(2, 36, 91, 0) 0%, #02245B 100%);
                    transition: all .3s ease-in-out;
                    opacity: 0;
                    visibility: hidden;
                }

                img {
                    width: 100%;
                    transition: all .3s ease-in-out;

                }
            }

            .text {
                position: absolute;
                width: 100%;
                top: 55%;
                left: 20px;
                transition: all .3s ease-in-out;
                opacity: 0;
                visibility: hidden;
                @media (max-width:1199px) {
                    left: 10px;
                }
                @media (max-width:991px) {
                    left: 20px;
                }

                span {
                    font-family: $heading-font;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: $text-light-color;

                }

                h2 {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 31px;
                    margin-bottom: 0;
                    color: $white;

                    @media (max-width:1199px) {
                        font-size: 21px;
                        line-height: 28px;
                    }

                    @media (max-width:767px) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }

                .theme-btn {
                    padding: 10px 15px;
                    position: absolute;
                    left: 0;
                    top: 100%;
                }
            }

            &:hover {
                .images {

                    &::after {
                        opacity: 1;
                        visibility: visible;
                    }

                    img {
                        transform: scale(1.5)
                    }
                }

                .text {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.wpo-project-area-s2.single{
 padding-bottom: 30px;

 .wpo-project-item{
     margin-bottom: 30px;
 }
}