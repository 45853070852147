/* Smaller font for office-info-text */
.office-info-text.smaller-font {
    font-size: 14px; /* Adjust the size as needed */
  }
  
  /* Styling for the separated working hours section */
  .working-hours-section {
    margin-top: 40px; /* Add spacing above the section */
    margin-bottom: 40px; /* Add spacing below the section */
    text-align: center; /* Center the text */
  }
  
  .working-hours-section h2 {
    font-size: 24px; /* Adjust the heading size */
    margin-bottom: 10px; /* Add spacing below the heading */
  }
  
  .working-hours-section p {
    font-size: 16px; /* Adjust the paragraph size */
    color: #555; /* Optional: Change text color */
  }
  
  /* Contact Title Section */
  .wpo-contact-title {
    text-align: center; /* Center the text */
    margin-top: 40px; /* Add spacing above the section */
    margin-bottom: 40px; /* Add spacing below the section */
  }
  
  .wpo-contact-title h2 {
    font-size: 28px; /* Adjust the heading size */
    margin-bottom: 10px; /* Add spacing below the heading */
  }
  
  .wpo-contact-title p {
    font-size: 18px; /* Adjust the paragraph size */
    color: #555; /* Optional: Change text color */
  }

  .office-info-text{
    p{

      font-size: .65rem !important;
    }
  }