/*--------------------------------------------------------------
4. wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer {
    background: url(../../images/footer-bg.jpg) center / cover no-repeat;
    position: relative;
    font-size: 15px;
    overflow: hidden;
    z-index: 1;

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        background: #000B47;
        opacity: .9;
    }

    ul {
        list-style: none;
    }

    p {
        color: $white;
    }

    li {
        color: $white;
    }

    .container {
        position: relative;
    }

    .wpo-upper-footer {
        padding: 70px 0;
    }

    @media (max-width: 991px) {

        .wpo-upper-footer {
            padding: 50px 0 0;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer {
            padding: 60px 0 0;
        }
    }

    @media (max-width: 991px) {

        .wpo-upper-footer .col {
            min-height: 235px;
            margin-bottom: 70px;
        }
    }

    @media (max-width: 767px) {

        .wpo-upper-footer .col {
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title {
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {

        .widget-title {
            margin-bottom: 20px;
        }
    }


    .widget-title {
        h3 {
            font-size: 30px;
            color: $white;
            margin: 0;
            position: relative;
            font-family: $heading-font;
            font-weight: 600;
            padding-bottom: 15px;

            &::before {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 80px;
                height: 2px;
                content: '';
                background: $theme-primary-color
            }
        }
    }

    .about-widget {
        .logo {
            max-width: 180px;
        }
    }

    .about-widget {
        p {
            margin-bottom: 15px;
            line-height: 1.9em;

        }
    }

    .wpo-service-link-widget {
        padding-left: 70px;
    }

    .link-widget {
        overflow: hidden;


        @media screen and (min-width: 1400px) {
            padding-left: 75px;
        }




        @media (max-width: 767px) {
            max-width: 350px;
        }

        ul {
            li {
                position: relative;

                a {
                    color: $white;
                    font-size: 18px;
                    text-transform: capitalize;

                    &:hover {
                        color: $theme-primary-color;
                    }
                }
            }

            li+li {
                padding-top: 15px;
            }
        }
    }



    .social-widget {
        ul {
            li {
                display: inline-block;
                margin-right: 5px;

                a {
                    text-align: center;
                    font-size: 14px;
                    color: $white;
                    background: $dark-gray;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    display: block;
                    transition: all .3s ease-in-out;

                    &:hover {
                        background: $theme-primary-color;
                    }


                }
            }
        }
    }

    .newsletter-widget {

        .email-from {
            position: relative;
            margin-top: 25px;

            .fild {
                width: 100%;
                padding: 14px 20px;
                border: 1px solid $theme-primary-color;
                font-size: 15px;
                color: $white;
                background: transparent;

                &:focus-visible {
                    outline: 0;
                }
            }

            button {
                border: 0;
                background: $theme-primary-color;
                color: $white;
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 45px;
                font-size: 19px;
                line-height: 55px;
            }

            ::-webkit-input-placeholder {
                color: $white;
            }

            ::-moz-placeholder {
                color: $white;
            }

            :-ms-input-placeholder {
                color: $white;
            }

            :-moz-placeholder {
                color: $white;
            }
        }
    }

    .wpo-lower-footer {
        text-align: center;
        position: relative;
        background: $dark-gray;

        .row {
            padding: 20px 0;
            position: relative;
        }

        .copyright {
            display: inline-block;
            font-size: 15px;
            font-size: 0.9375rem;
            margin: 0;

            a {
                color: $white;
                text-decoration: none;
            }

            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }
    }


}