/*=====================================================
6. Home-style-3
======================================================*/


/*6.1 wpo-about-section-s3 */
.wpo-about-section-s3 {

    .about-left-image {
        position: relative;

        &::before {
            display: none;
        }

        .image {

            img {
                width: 100%;
            }
        }

        .experience {
            width: 250px;
            height: 200px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: $white;
            position: absolute;
            right: 0;
            bottom: 0;

            @media (max-width:1199px) {
                width: 200px;
                height: 180px;
            }

            @media (max-width:575px) {
                width: 180px;
                height: 150px;
            }

            h2 {
                margin-bottom: 0;

                span {
                    color: transparent;
                    font-size: 100px;
                    font-weight: 900;
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: $heading-color;

                    @media (max-width:1199px) {
                        font-size: 80px;
                    }

                    @media (max-width:575px) {
                        font-size: 60px;
                    }
                }
            }


            h3 {
                color: $heading-color;
                font-size: 20px;
                font-weight: 700;

                @media (max-width:575px) {
                    font-size: 18px;
                }

                span {
                    color: $theme-primary-color;
                }
            }
        }
    }
}

/*6.2 wpo-service-section-s3 */
.wpo-service-section-s3 {
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 60px;
    }

     &.s3 {
        padding-top: 120px;

        @media (max-width:991px) {
            padding-top: 90px;
        }

        @media (max-width:767px) {
            padding-top: 80px;
        }
    }
    .service-card {
        .content {
            display: flex;

            @media (max-width:455px) {
                flex-direction: column;
            }

            .icon {
                margin-top: 0;
                width: 100px;
                height: 100px;
                line-height: 100px;

                @media (max-width:455px) {
                    width: 90px;
                    height: 90px;
                    line-height: 90px;
                }

                img {
                    width: 60px;
                    height: 60px;

                    @media (max-width:455px) {
                        width: 40px;
                        height: 40px;
                    }
                }
            }

            .text {
                width: calc(100% - 100px);
                margin-left: 20px;

                @media (max-width:455px) {
                    width: 100%;
                    margin-left: 0px;
                    margin-top: 20px;
                }



                h2 {
                    margin-top: 0;
                }
            }
        }
    }
}

/*6.3 wpo-feature-section-s3 */
.wpo-feature-section-s3 {
    position: unset;
    margin-top: 0;
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 60px;
    }

    .item {
        position: relative;
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        @media (max-width:1199px) {
            flex-direction: column;
            text-align: center;
        }

        .icon {
            text-align: center;
            width: 100px;
            height: 100px;
            line-height: 100px;
            background: $theme-primary-color;
            border-radius: 10px;

            @media (max-width:991px) {
                width: 90px;
                height: 90px;
                line-height: 90px;
            }
        }

        .content {
            margin-left: 20px;

            @media (max-width:1199px) {
                margin-left: 0;
                margin-top: 30px;
            }

            h2 {
                color: $heading-color;
                font-size: 40px;
                font-weight: 600;
                margin-bottom: 0;

                @media (max-width:575px) {
                    font-size: 35px;
                }

                span {
                    color: $heading-color;
                    font-size: 40px;
                    font-weight: 600;

                    @media (max-width:575px) {
                        font-size: 35px;
                    }
                }
            }


            span {
                color: $small-black;
                font-size: 16px;
                font-weight: 500;
                text-transform: capitalize;
            }
        }
    }
}

/*6.4 wpo-partners-section */
.wpo-partners-section {
    position: relative;
    padding: 60px 0 60px;

    @media (max-width:991px) {
        padding: 60px 0 60px;
    }

    @media (max-width:767px) {
        padding: 50px 0 50px;
    }

    h2 {
        display: none;
    }

    .partners-img {

        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }



    }

    .grid {
        height: 80px;
        display: flex;
        align-items: center;

        &:hover {

            img {
                filter: grayscale(100%);
            }
        }
    }


    .grid img {
        width: auto;
        margin: 0 auto;
        transition: all .4s ease-in-out;
    }

    .owl-nav {
        button {
            display: none;
        }
    }

    &.wpo-partners-section-s2 {
        background: #02245B;
        opacity: 0.8;
    }
}

/*--------------------------------------------------------------
6.5 wpo-pricing-page
--------------------------------------------------------------*/
.wpo-pricing-section {

	@include media-query(991px) {
		padding-bottom: 75px;
	}

	@include media-query(767px) {
		padding-bottom: 65px;
	}

	.pricing-grids {
		margin: 0 -15px;
        padding-top: 50px;
	}

	.pricing-grids .grid {
		width: calc(33.33% - 30px);
		float: left;
		margin: 0 15px 0;
		text-align: center;
		padding: 0 45px 55px;
		border: 1px solid #f9deba;
		border-radius: 5px;
		box-shadow: 0px 7px 13.5px 1.5px rgba(0, 0, 0, 0.05);

		@include media-query(1199px) {
			padding: 0 15px 55px;
		}

		@include media-query(991px) {
			width: calc(50% - 30px);
			margin: 0 15px 30px;
		}

		@include media-query(650px) {
			width: calc(100% - 30px);
			float: none;
		}
	}

	.grid .type {
		background-color: #f3f3f3;
		display: inline-block;
		padding: 12px 60px;
		margin-top: 40px;
        border-radius: 30px;

		@include media-query(991px) {
			padding: 10px 40px;
		}

		h5 {
			font-size: 25px;
			margin: 0;
			text-transform: uppercase;

			@include media-query(991px) {
				font-size: 16px;
			}
		}
	}

	.pricing-header {
		background: #fff;
		width: 180px;
		height: 180px;
		border-radius: 50%;
		margin: 30px auto;
		display: table;
        box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);

		@include media-query(991px) {
			width: 140px;
			height: 140px;
		}

		> div {
			display: table-cell;
			vertical-align: middle;
		}

		h3 {
			font-size: 50px;
			font-size: calc-rem-value(50);
			margin: 0;

			@include media-query(991px) {
				font-size: 40px;
			}
		}

		p {
			font-size: 18px;
			font-size: calc-rem-value(18);
			color: $theme-primary-color;
			margin: 0;

			@include media-query(991px) {
				font-size: 14px;
			}
		}
	}

	.pricing-body ul {
		margin-bottom: 50px;
        list-style: none;

		@include media-query(991px) {
			margin-bottom: 40px;
		}

		li {
			@include media-query(1199px) {
				font-size: 18px;
			}
		}
	}

	.pricing-body ul > li + li {
		margin-top: 12px;
	}

	.grid .get-started {
		display: block;
		padding: 13px 0;
		border: 1px solid #f9deba;
		border-radius: 5px;
		color: $theme-primary-color;
		font-weight: 500;

		@include media-query(1199px) {
			padding: 10px 0;
			margin: 0 25px;
		}
	}

	.grid .get-started:hover {
		background: $theme-primary-color;
		color: $white;
	}


	.pricing-grids > .grid:nth-child(2) {
        position: relative;
        z-index: 1;
        &:before{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(48, 52, 67, 0.9);
            content: "";
            z-index: -1;
            border-radius: 5px;
         }

		@include widther(1200px) {
			margin-top: -30px;
		}

        ul{
            li{
                color: #ddd;
            }
        }

		.type {
			background-color: #062650;

			h5 {
				color: $white;
			}
		}

		.pricing-header {
			background: #062650;
            .price{
                color:$white;
            }
		}

		.get-started {
			background: $theme-primary-color;
			color: $white;
		}
		.get-started:hover {
			background: darken($theme-primary-color, 8%);
			color: $white;
		}
	}
}
